<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
        btnTitle="Add New"
        :showBtn="false"
        :showAddNew="true"
        title="Purchase Bill Manager"
        @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="px-2">
      <DateQuerySetter
        btnTitle="Go"
        @onClickGo="getPurchaseList"
      >

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Supplier: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
          </div>

          <AsyncSelect
            placeholder="Select Supplier"
            v-model="contactProfile"
            :api-service="fetchContactProfiles"
            :additional-query="additionalContactOption"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Bill Number: </label>
          </div>

          <input
            placeholder="Bill Number"
            v-model="billNumber"
            class="form-control"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12 px-2 mt-1">
      <ListTable :purchase="purchaseList" :company-info="companyInfo"/>
    </div>

    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>

    <ContactDropdownFilter
      v-if="store.state.isModalOpenThree"
      @onSearchContact="onSearchContact"
      type="customer"
    />

    <GlobalLoader />
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, onMounted, computed  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from './ListTable.vue'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleContact from "@/services/modules/contact";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {trim} from "lodash";
import handleCompany from "@/services/modules/company";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
const route = useRoute()
let contactProfile = ref(null)
let billNumber = ref(null)
let companyInfo = ref(null)

const {fetchPurchaseList} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const { fetchCompanyInfo } = handleCompany();

const companyId = computed(() => {
  return route.params.companyId
})

const additionalContactOption = ref({
  roles: 'supplier'
});

function navigateToAddPage() {
  router.push({name: 'purchase-bill-pharmacy', params: route.params, query: route.query})
}

function getPurchaseList() {
  const query = {
    company_id: companyId.value,
    start_date: route.query.start,
    end_date: route.query.end,
    page: route.query.page,
  };

  if (contactProfile.value) {
    query.contact_profile_id = contactProfile.value.id;
  }

  if (contactProfile.value) {
    query.contact_profile_id = contactProfile.value.id;
  }

  if (billNumber.value && trim(billNumber.value) !== '') {
    query.bill_number = trim(billNumber.value);
  }

  const queryString = new URLSearchParams(query);

  fetchPurchaseList("?" + queryString.toString())
    .then(res => {
      if (res.status) {
        purchaseList.value = res.date.data
        setPagination(res.date)
      } else {
        purchaseList.value = []
        store.commit("resetPagination")
      }

    })
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getPurchaseList()
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

onMounted(() => {
  getPurchaseList()
  fetchCompanyInfo(route.params.companyId).then(res => {
    if (res.status) {
      companyInfo.value = res.data
    }
  })

})

</script>
